import { store } from "@/store.js";

export function _isAllowedByCalendar(
  cal,
  daysOfSemester,
  schoolIsWithClosingDate,
  currentSelectedSemester,
  teacher
) {
  // console.log(cal.xlesson.lessonSettingData);
  var found = daysOfSemester.find(
    (dd) =>
      dd.weekNumber == cal.actualDate.weekNumber &&
      dd.garag == cal.actualDate.garag &&
      cal.calData.semester == currentSelectedSemester
  );

  if (
    found &&
    found.finished &&
    found.finished.find(
      (academicLevel) =>
        academicLevel == Number(cal.lesson.classGroups[0].ACADEMIC_LEVEL)
    ) != undefined
  )
    found = null;

  var startingYear = null;
  var startingMonth = null;
  var startingDay = null;
  var closingYear = null;
  var closingMonth = null;
  var closingDay = null;
  //
  var beforeCutOff = false;

  var calDATE = new Date(
    cal.actualDate.year + "-" + cal.actualDate.month + "-" + cal.actualDate.day
  );
  calDATE.setHours(0, 0, 0, 0);

  if (cal.lesson && cal.lesson.lessonCloseDate && schoolIsWithClosingDate) {
    closingYear = cal.lesson.lessonCloseDate.split("-")[0];
    closingMonth =
      cal.lesson.lessonCloseDate.split("-")[1][0] == "0"
        ? cal.lesson.lessonCloseDate.split("-")[1][1]
        : cal.lesson.lessonCloseDate.split("-")[1];
    closingDay =
      cal.lesson.lessonCloseDate.split("-")[2][0] == "0"
        ? cal.lesson.lessonCloseDate.split("-")[2][1]
        : cal.lesson.lessonCloseDate.split("-")[2];
  }
  // console.log(cal.lesson, "cal.lesson.", cal.lesson.classGroups);
  var academicLevel = cal.lesson.classGroups[0].ACADEMIC_LEVEL;
  if (
    academicLevel &&
    store.state.calendarButez2[currentSelectedSemester - 1] &&
    store.state.calendarButez2[currentSelectedSemester - 1].cutOff &&
    store.state.calendarButez2[currentSelectedSemester - 1].cutOff[
      academicLevel
    ]
  ) {
    var cutOffDate =
      store.state.calendarButez2[currentSelectedSemester - 1].cutOff[
        academicLevel
      ].end;
    if (
      new Date(
        cal.actualDate.year +
          "-" +
          cal.actualDate.month +
          "-" +
          cal.actualDate.day
      ) <=
      new Date(cutOffDate.year + "-" + cutOffDate.month + "-" + cutOffDate.day)
    ) {
      beforeCutOff = true;
    }
  } else if (
    !(
      store.state.calendarButez2[currentSelectedSemester - 1] &&
      store.state.calendarButez2[currentSelectedSemester - 1].cutOff
    )
  ) {
    beforeCutOff = true;
  }
  //this says if it is in this semester
  var allowed = true;
  if (
    cal.lesson &&
    cal.lesson.startEnd &&
    cal.lesson.startEnd["semester-" + currentSelectedSemester] &&
    cal.lesson.startEnd["semester-" + currentSelectedSemester].available
  ) {
    var noFilter1 = false;
    var noFilter2 = false;
    if (cal.lesson.startEnd["semester-" + currentSelectedSemester].start) {
      startingYear =
        cal.lesson.startEnd["semester-" + currentSelectedSemester].start.split(
          "-"
        )[0];
      startingMonth =
        cal.lesson.startEnd["semester-" + currentSelectedSemester].start.split(
          "-"
        )[1][0] == "0"
          ? cal.lesson.startEnd[
              "semester-" + currentSelectedSemester
            ].start.split("-")[1][1]
          : cal.lesson.startEnd[
              "semester-" + currentSelectedSemester
            ].start.split("-")[1];
      startingDay =
        cal.lesson.startEnd["semester-" + currentSelectedSemester].start.split(
          "-"
        )[2][0] == "0"
          ? cal.lesson.startEnd[
              "semester-" + currentSelectedSemester
            ].start.split("-")[2][1]
          : cal.lesson.startEnd[
              "semester-" + currentSelectedSemester
            ].start.split("-")[2];
    } else {
      noFilter1 = true;
    }

    if (cal.lesson.startEnd["semester-" + currentSelectedSemester].end) {
      closingYear =
        cal.lesson.startEnd["semester-" + currentSelectedSemester].end.split(
          "-"
        )[0];
      closingMonth =
        cal.lesson.startEnd["semester-" + currentSelectedSemester].end.split(
          "-"
        )[1][0] == "0"
          ? cal.lesson.startEnd[
              "semester-" + currentSelectedSemester
            ].end.split("-")[1][1]
          : cal.lesson.startEnd[
              "semester-" + currentSelectedSemester
            ].end.split("-")[1];
      closingDay =
        cal.lesson.startEnd["semester-" + currentSelectedSemester].end.split(
          "-"
        )[2][0] == "0"
          ? cal.lesson.startEnd[
              "semester-" + currentSelectedSemester
            ].end.split("-")[2][1]
          : cal.lesson.startEnd[
              "semester-" + currentSelectedSemester
            ].end.split("-")[2];
    } else {
      noFilter2 = true;
    }
    if (
      (noFilter1 || noFilter2) &&
      cal.lesson.classGroups[0].programData &&
      cal.lesson.classGroups[0].programData.calendarVersions
    ) {
      //TODO CHANGE TO "OR" WHEN CALENDAR VERSION 2 IS NEEDED
      var calCalendarVersion =
        cal.lesson.classGroups[0].programData.calendarVersions.find(
          (version) =>
            Number(version.calendarVersionNumber) ==
            Number(cal.calData.calendarVersion)
        );
      if (calCalendarVersion) {
        var startDate = new Date(calCalendarVersion.startDate);
        var endDate = new Date(calCalendarVersion.endDate);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
        if (!(startDate <= calDATE && calDATE <= endDate)) {
          allowed = false;
        }
      }
    }
  } else if (
    cal.lesson &&
    cal.lesson.startEnd &&
    cal.lesson.startEnd["semester-" + currentSelectedSemester] &&
    cal.lesson.startEnd["semester-" + currentSelectedSemester].available ==
      false
  ) {
    allowed = false;
  }

  var foundTeacher = cal.lesson.byTeachers.find(
    (t) =>
      (Number(t.teacherId) == Number(teacher.PERSON_ID) ||
        String(t.teacherId) == String(teacher.id)) &&
      (t.xSemester == currentSelectedSemester || !t.xSemester)
  );
  if (foundTeacher) {
    //teacher start end filter
    if (foundTeacher.startDate) {
      startDate = new Date(foundTeacher.startDate);
      startDate.setHours(0, 0, 0, 0);
      if (startDate > calDATE) {
        allowed = false;
      }
    }
    if (foundTeacher.endDate) {
      endDate = new Date(foundTeacher.endDate);
      endDate.setHours(0, 0, 0, 0);
      if (endDate < calDATE) {
        allowed = false;
      }
    }
  } else {
    allowed = false;
  }
  if (found && allowed && beforeCutOff) {
    if (teacher.school.cityCode != "01" && found.isForCountryHoliday) {
      return null;
    }

    // console.log(
    //   cal.actualDate.month + "-" + cal.actualDate.day,
    //   "XXXXXX!!!"
    // );
    if (
      cal.lesson &&
      cal.lesson.lessonSettingData &&
      cal.lesson.lessonSettingData["isForTwoWeek"] &&
      cal.lesson.lessonSettingData["isForTwoWeek"][currentSelectedSemester - 1]
    ) {
      if (
        cal.lesson.lessonSettingData["isEvenWeek"][
          currentSelectedSemester - 1
        ] != undefined &&
        cal.lesson.lessonSettingData["isEvenWeek"][
          currentSelectedSemester - 1
        ] != null &&
        cal.lesson.lessonSettingData["isEvenWeek"][currentSelectedSemester - 1][
          cal.id
        ] != undefined &&
        cal.lesson.lessonSettingData["isEvenWeek"][currentSelectedSemester - 1][
          cal.id
        ] != null
      ) {
        if (
          cal.actualDate.weekNumber % 2 ==
          cal.lesson.lessonSettingData["isEvenWeek"][
            currentSelectedSemester - 1
          ][cal.id]
        ) {
          if (
            (startingYear && startingMonth && startingDay) ||
            (closingYear && closingMonth && closingDay)
          ) {
            let failed = false;
            if (startingYear && startingMonth && startingDay) {
              if (
                !(
                  new Date(found.year + "-" + found.month + "-" + found.day) >=
                  new Date(
                    startingYear + "-" + startingMonth + "-" + startingDay
                  )
                )
              ) {
                failed = true;
              }
            }

            if (closingYear && closingMonth && closingDay) {
              if (
                !(
                  new Date(found.year + "-" + found.month + "-" + found.day) <=
                  new Date(closingYear + "-" + closingMonth + "-" + closingDay)
                )
              ) {
                failed = true;
              }
            }
            return failed == false ? found : null;
          } else {
            return found;
          }
        } else return null;
      } else {
        if (
          (startingYear && startingMonth && startingDay) ||
          (closingYear && closingMonth && closingDay)
        ) {
          let failed = false;
          if (startingYear && startingMonth && startingDay) {
            if (
              !(
                new Date(found.year + "-" + found.month + "-" + found.day) >=
                new Date(startingYear + "-" + startingMonth + "-" + startingDay)
              )
            ) {
              failed = true;
            }
          }

          if (closingYear && closingMonth && closingDay) {
            if (
              !(
                new Date(found.year + "-" + found.month + "-" + found.day) <=
                new Date(closingYear + "-" + closingMonth + "-" + closingDay)
              )
            ) {
              failed = true;
            }
          }
          return failed == false ? found : null;
        } else {
          return found;
        }
      }
    } else {
      if (
        (startingYear && startingMonth && startingDay) ||
        (closingYear && closingMonth && closingDay)
      ) {
        let failed = false;
        if (startingYear && startingMonth && startingDay) {
          if (
            !(
              new Date(found.year + "-" + found.month + "-" + found.day) >=
              new Date(startingYear + "-" + startingMonth + "-" + startingDay)
            )
          ) {
            failed = true;
          }
        }

        if (closingYear && closingMonth && closingDay) {
          if (
            !(
              new Date(found.year + "-" + found.month + "-" + found.day) <=
              new Date(closingYear + "-" + closingMonth + "-" + closingDay)
            )
          ) {
            failed = true;
          }
        }
        return failed == false ? found : null;
      } else {
        return found;
      }
    }
  } else return null;
}

export async function handleStatistics( //1226
  irzuud,
  lesson,
  eeljitTopics,
  statistics,
  currentSelectedSemester,
  fullAutomaticallyAdded,
  selectedTeacher
) {
  //_getTopicStringK(calIndex, name=[name, homework,activities=,result])
  //irzuud is the all the irzuud

  console.log(
    "dfasdfsf",
    lesson.id,
    irzuud.length,
    statistics,
    eeljitTopics.length,
    fullAutomaticallyAdded,
    currentSelectedSemester
  );
  if (irzuud && irzuud.length > 0) {
    var mustHomework = 0;
    var mustTopic = 0;
    var mustNotes = 0;
    var mustConclusion = 0;

    var doneHomework = 0;
    var doneTopic = 0;
    var doneNotes = 0;
    var doneConclusion = 0;

    var notRegularTopic = 0;
    var notRegularHomework = 0;
    var notRegularNotes = 0;
    var notRegularConclusion = 0;

    var mustAtt = 0;
    var doneAtt = 0;
    var notRegularAtt = 0;
    for (var i = 0; i < irzuud.length; i++) {
      var irz = irzuud[i];
      if (irz.actualDate && !irz.actualDate.isHoliday) {
        mustAtt++;
        mustNotes++;
        mustConclusion++;
        mustHomework++;
        mustTopic++;
        // console.log(irz.execution);
        if (irz.execution) {
          //if it is normal
          if (irz.execution.regular != false) {
            doneAtt++;
          } else {
            mustAtt--;
            mustNotes--;
            mustConclusion--;
            mustHomework--;
            mustTopic--;
            notRegularAtt++;
          }

          //new version

          if (fullAutomaticallyAdded) {
            var topicExits = _getTopicStringK2(i, "name", eeljitTopics);
            if (irz.execution.regular != false && topicExits) doneTopic++;
            // else notRegularTopic++;

            var homeworkExits = _getTopicStringK2(i, "homework", eeljitTopics);
            if (irz.execution.regular != false && homeworkExits) doneHomework++;
            // else notRegularHomework++;

            var activitiesExits = _getTopicStringK2(
              i,
              "activities",
              eeljitTopics
            );
            if (irz.execution.regular != false && activitiesExits) doneNotes++;
            // else notRegularNotes++;

            var resultsExits = _getTopicStringK2(i, "result", eeljitTopics);
            if (irz.execution.regular != false && resultsExits)
              doneConclusion++;
            // else notRegularConclusion++;
            // console.log(
            //   doneTopic,
            //   doneHomework,
            //   doneNotes,
            //   doneConclusion,
            //   "aaa"
            // );
          } else {
            if (irz.execution.lessonConclusion) {
              // doneConclusion++;
              if (irz.execution.regular != false) doneConclusion++;
              else notRegularConclusion++;
            }
            if (irz.execution.lessonNotes) {
              // doneNotes++;
              if (irz.execution.regular != false) doneNotes++;
              else notRegularNotes++;
            }
            if (irz.execution.selectedHomeWork) {
              // doneHomework++;
              if (irz.execution.regular != false) doneHomework++;
              else notRegularHomework++;
            }
            if (_hasExecutionAnyTopic(irz.execution)) {
              // doneTopic++;
              if (irz.execution.regular != false) doneTopic++;
              else notRegularTopic++;
            }
          }
        }
      } //if it is a working day
    }
    // console.log(doneAtt)
    var tmp = {
      mustAtt: mustAtt,
      mustHomework: mustHomework,
      mustTopic: mustTopic,
      mustNotes: mustNotes,
      mustConclusion: mustConclusion,

      //   doneAtt: 1,
      doneAtt: doneAtt,
      doneHomework: doneHomework,
      doneTopic: doneTopic,
      doneNotes: doneNotes,
      doneConclusion: doneConclusion,

      notRegularAtt: notRegularAtt,
      notRegularTopic: notRegularTopic,
      notRegularHomework: notRegularHomework,
      notRegularNotes: notRegularNotes,
      notRegularConclusion: notRegularConclusion,
    };
    // console.log("tmp222", tmp,statistics["semester-" + currentSelectedSemester][lesson.id]);
    // console.log("ELSE ELSE22244",statistics["semester-" + currentSelectedSemester][lesson.id].doneTopic >
    //   tmp.doneTopic)
    // if (
    //   statistics["semester-" + currentSelectedSemester] &&
    //   statistics["semester-" + currentSelectedSemester][lesson.id]
    // ) {
    //   if (
    //     statistics["semester-" + currentSelectedSemester][lesson.id].doneAtt >
    //       tmp.doneAtt ||
    //     statistics["semester-" + currentSelectedSemester][lesson.id]
    //       .doneHomework > tmp.doneHomework ||
    //     statistics["semester-" + currentSelectedSemester][lesson.id].doneTopic >
    //       tmp.doneTopic ||
    //     statistics["semester-" + currentSelectedSemester][lesson.id].doneNotes >
    //       tmp.doneNotes ||
    //     statistics["semester-" + currentSelectedSemester][lesson.id]
    //       .doneConclusion > tmp.doneConclusion
    //   ) {
    //     return;
    //   }
    //   // }else{
    //   //   console.log("ELSE ELSE")
    // }
    // console.log("ELSE 3333");
    if (!statistics["semester-" + currentSelectedSemester])
      statistics["semester-" + currentSelectedSemester] = {};
    statistics["semester-" + currentSelectedSemester][lesson.id] = tmp;

    console.log("final....stat", statistics);
    if (statistics) {
      console.log(tmp, "statitis final");
      if (statistics.ref) {
        await statistics.ref.set(statistics, { merge: true }).then(() => {
          console.log("updated statistics finalally!!!");
          console.log(statistics, statistics.ref.path);
        });
      } else {
        await selectedTeacher.school.ref
          .collection(
            "teachersExecutionOverview-" + selectedTeacher.school.currentYear
          )
          .doc(selectedTeacher.id)
          .set(statistics, { merge: true })
          .then(() => {
            console.log("111");
          });
      }
    }
  } else {
    var tmp2 = {
      mustAtt: irzuud.length,
      doneAtt: 0,
      mustHomework: 0,
      mustTopic: 0,
      mustNotes: 0,
      mustConclusion: 0,
      doneHomework: 0,
      doneTopic: 0,
      doneNotes: 0,
      doneConclusion: 0,
      notRegularAtt: 0,
    };
    if (!statistics["semester-" + currentSelectedSemester])
      statistics["semester-" + currentSelectedSemester] = {};
    statistics["semester-" + currentSelectedSemester][lesson.id] = tmp2;
    if (statistics) {
      if (statistics.ref) {
        await statistics.ref.set(statistics, { merge: true });
      } else {
        await selectedTeacher.school.ref
          .collection(
            "teachersExecutionOverview-" + selectedTeacher.school.currentYear
          )
          .doc(selectedTeacher.id)
          .set(statistics, { merge: true });
      }
    }
  }
}

function _hasExecutionAnyTopic(execution) {
  var zz =
    execution.selectedParentTopic ||
    execution.selectedSubTopic ||
    execution.selectedSubSubTopicDescription ||
    execution.selectedSubSubTopic ||
    execution.selectedPreviousPlannedTopic;
  if (zz) return true;
  return false;
}

function _getTopicStringK2(calIndex, kkey, eeljitTopics) {
  if (eeljitTopics) {
    var found = eeljitTopics[calIndex];
    return found && found[kkey] ? found[kkey] : null;
  }
  return null;
}

export function _getStatisticsPercent(
  statistics,
  lessonId,
  keyWord,
  currentSelectedSemester
) {
  //keyWord: Att, Topic, Homework, Notes, Conclusion
  if (lessonId) {
    var must = 0;
    var done = 0;
    if (
      statistics &&
      statistics["semester-" + currentSelectedSemester] &&
      statistics["semester-" + currentSelectedSemester][lessonId]
    ) {
      must =
        statistics["semester-" + currentSelectedSemester][lessonId][
          "must" + keyWord
        ];

      var nonRegular =
        statistics["semester-" + currentSelectedSemester][lessonId][
          "notRegular" + keyWord
        ];

      done =
        statistics["semester-" + currentSelectedSemester][lessonId][
          "done" + keyWord
        ];

      var final = (done ? done : 0) + (nonRegular ? nonRegular : 0);
      var zz = 0;
      if (must && must != 0) {
        zz = (final / must) * 100;
        if (zz >= 100) return 100;
        else return zz;
      } else return 0;
    }
  }
  return 0;
}

export function _getOverAllStatisticsPercent(
  statistics,
  lessonId,
  currentSelectedSemester
) {
  var x1 = _getStatisticsPercent(
    statistics,
    lessonId,
    "Att",
    currentSelectedSemester
  );
  var x2 = _getStatisticsPercent(
    statistics,
    lessonId,
    "Topic",
    currentSelectedSemester
  );
  var x3 = _getStatisticsPercent(
    statistics,
    lessonId,
    "Homework",
    currentSelectedSemester
  );
  var x4 = _getStatisticsPercent(
    statistics,
    lessonId,
    "Notes",
    currentSelectedSemester
  ); //activities
  var x5 = _getStatisticsPercent(
    statistics,
    lessonId,
    "Conclusion",
    currentSelectedSemester
  ); //result

  var x6 = x4 <= x5 ? x5 : x4;
  // return (x1 + x2 + x3 + x4 + x5) / 5;
  return (x1 + x2 + x3 + x6) / 4;
}
